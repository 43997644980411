<template>
  <div class="account">
    <div class="cover"></div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 align-self-center">
          <h1 class="info">Reise, so wie du bist.</h1>
        </div>
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-2">
          <div class="card">
            <h4 class="card-title">Passwort ändern</h4>
            
              <div class="register-container form">
                <div class="form-group">
                  <span class="material-icons light left">lock</span>
                  <input type="password" class="form-control icon-left lg"  @blur="validatePasswordInput" v-model="credentials.password" placeholder="Passwort"/>
                </div>
                <span class="validation-error" v-show="showPasswordError">Geben Sie ein Passwort ein. Das Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl enthalten.</span>
              </div>
              <div class="register-container">
                <div class="form-group">
                  <span class="material-icons light left">lock</span>
                  <input type="password" class="form-control icon-left lg"  @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder="Passwort wiederholen"/>
                </div>
                <span class="validation-error" v-show="showPasswordRepeatError">Die Passwörter stimmen nicht überein.</span>
              </div>
              <div class="form-group">
                <button type="submit" @click="handleReset" style="width: 100%;" class="btn btn-fill btn-focus-design">Passwort ändern</button>
              </div>

              <div class="login-row">
                <div class="text-center btn-focus-design">Schon dabei?</div>
                <router-link class="btn btn-link to-login" to="/login">Zum Login</router-link>
              </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { csrf, resetPassword } from '@/api/auth';
  import { validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'password-forgotten',
    data() {
      return {
       credentials: {
        token: '',
        password: "",
        passwordRepeat: "",
      },
      showPasswordError: false,
      showPasswordRepeatError: false,
      loader: false,
    };
  },
  mounted() {
    if(this.$route.params.token){
      //console.log(this.$route.params.token);
      this.credentials.token = this.$route.params.token;
    }
  },
  methods: { 
    showLoader,
    hideLoader,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    handleReset() {
      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      if(!passwordValid || !passwordRepeatValid){
        return;
      }
      this.loader = this.showLoader(this.loader);
      csrf().then(() => {
        resetPassword(this.credentials).then(() => {
         this.$notify({
          duration: 3500,
          title: 'Passwort zurückgesetzt!',
          text: 'Das Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort anmelden!'
        });

         this.$router.push({name:'Login'});

       })
        .catch(() => {
         this.$notify({
          type: 'error',
          duration: 3500,
          title: 'Passwort zurückzusetzen fehlgeschlagen!',
          text: 'Die Zurücksetzung ist fehlgeschlagen. Bitte probieren Sie es zu einem späteren Zeitpunk noch einmal!'
        });
       }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
     });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .validation-error{
    color:#E5321B;
    font-size: 13px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .register-container {
    margin-bottom: 1rem;
  }

  .to-login {
    width: auto !important;
  }

  .btn {
    margin-top: 0 !important;
  }

  .login-row {
    margin-top: 2rem;
  }
</style>
